import React from 'react';
import { BlogPost, WebsiteWrapper } from '../../parts';
import PageWrapper from '../../containers/PageWrapper';

const BlogPostPage = ({ location, pageContext }) => {
  const { lang, translations } = pageContext;
  return (
    <PageWrapper lang={lang} translations={translations} noMetas={true}>
      <WebsiteWrapper
        isNavBgWhite={true}
        isBlogArticle={true}
        location={location}
      >
        <BlogPost pageContext={pageContext} />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default BlogPostPage;
